import React from "react";
// import chevron from "../Lending/chevron-rightarrow.svg";

import "./stylePageFooter.scss";




const PageFooter = () =>{


    return (
        <>

            <div className="landing__bottom-content">
                <div className="landing__bottom-wrap">
                    <div className="landing__bottom-left-module">

                      

                        <div className="landing__bottom-content__networks">


                            <a rel="noreferrer" target="_blank" className="menu-item" href="https://twitter.com/wsb_sh?s=21">
                                <div>
                                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":1, fontSize: '16px'}}>W</span>
                                    <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                    <span style={{"--index":3, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":4, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":5, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":6, fontSize: '16px'}}>R</span>
                                </div>
                                <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":1, fontSize: '16px'}}>W</span>
                                    <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                    <span style={{"--index":3, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":4, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":5, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":6, fontSize: '16px'}}>R</span>
                                </div>
                            </a>


                            {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TWITTER</a></span>*/}
                        </div>

                        <div className="landing__bottom-content__networks">

                            <a rel="noreferrer" target="_blank" className="menu-item" href="https://t.me/wsbsh">
                                <div>
                                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":2, fontSize: '16px'}}>L</span>
                                    <span style={{"--index":3, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                    <span style={{"--index":5, fontSize: '16px'}}>R</span>
                                    <span style={{"--index":6, fontSize: '16px'}}>A</span>
                                    <span style={{"--index":7, fontSize: '16px'}}>M</span>

                                </div>
                                <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":2, fontSize: '16px'}}>L</span>
                                    <span style={{"--index":3, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                    <span style={{"--index":5, fontSize: '16px'}}>R</span>
                                    <span style={{"--index":6, fontSize: '16px'}}>A</span>
                                    <span style={{"--index":7, fontSize: '16px'}}>M</span>
                                </div>
                            </a>


                            {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                        </div>
                        <div className="landing__bottom__separator-line"></div>
                        <div className="landing__bottom-content__networks">

                            <a rel="noreferrer" target="_blank" className="menu-item" href="/terms">
                                <div>
                                    <span style={{"--index":0, fontSize: '16px'}}>L</span>
                                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":2, fontSize: '16px'}}>G</span>
                                    <span style={{"--index":3, fontSize: '16px'}}>A</span>
                                    <span style={{"--index":4, fontSize: '16px'}}>L</span>
                                </div>
                                <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                                    <span style={{"--index":0, fontSize: '16px'}}>L</span>
                                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                    <span style={{"--index":2, fontSize: '16px'}}>G</span>
                                    <span style={{"--index":3, fontSize: '16px'}}>A</span>
                                    <span style={{"--index":4, fontSize: '16px'}}>L</span>
                                </div>
                            </a>

                            {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                        </div>
                        <div className="landing__bottom__separator-line"></div>
                        <div className="landing__bottom-content__networks">

                          <a rel="noreferrer" target="_blank" className="menu-item" href="https://www.coingecko.com/en/coins/wsb-sh">
                              <div>
                                  <span style={{"--index":0, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":1, fontSize: '16px'}}>O</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>N</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>E</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>K</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>O</span>
                              </div>
                              <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '16px'}}>
                                  <span style={{"--index":0, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":1, fontSize: '16px'}}>O</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>N</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>E</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>K</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>O</span>
                              </div>
                          </a>

                        {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                        </div>
                       

                        


                    </div>
                </div>
            </div>

            
        
        </>
    )
}

export default PageFooter