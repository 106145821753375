import React from "react";
import "./style.scss";
import img1 from './assets/img/leader1.png'
import img2 from './assets/img/leader2.png'
import img3 from './assets/img/leader3.png'
import img4 from './assets/img/leader4.png'
import un from './assets/img/Frame 15.svg'
import linkedin from './assets/img/Vector.svg'
import forbes from './assets/img/forbes-seeklogo.com 1.svg'

import PageFooter from "../PageFooter";



export default function Contact() {


  return (
    <div className="footer leadership__section">
      <div className="container leadership">
          <div>


              <h2 data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-speed="7"
                  className="leadership__title">
                Leadership
              </h2>


              {/*<PageFooter />*/}



              <div className="leadership__list"
                   data-scroll
                   data-scroll-direction="vertical"
                   data-scroll-speed="5"  //15
                   data-scroll-class="appear"
                   data-scroll-repeat="true"
              >




                  <div className="leadership__item"  >
                    <div className="leadership__item-img">
                      <img src={img1} alt="" />
                    </div>
                    <div className="leadership__item-content">
                      <h3 className="leadership__item-title font-EB">
                        Angel Versetti
                      </h3>
                      <h4 className="leadership__item-undertitle font-EB">
                        Strategy
                      </h4>
                      <p className="leadership__item-paragraph font-EB">
                        12 years track record as entrepreneur. Forbes 30 under 30 Europe.
                        Founder of Ambrosus, SecuChip, Smart Security Systems, doge.org, Moon Rabbit.
                        Shadow executive of Syscoin. Owner of Versetti&Co Family Office.
                          Former United Nations Project Lead.                      </p>
                      <ul className="leadership__item-socials">
                        <a href="https://www.forbes.com/profile/angel-versetti/" alt="forbes" target="_blank" style={{cursor:'pointer'}}>
                        <img src={forbes} alt="" />
                        </a>

                        <a href="https://www.linkedin.com/in/angelversetti/" alt="forbes" target="_blank" style={{cursor:'pointer'}}>
                        <img src={linkedin} alt="" />
                        </a>

                        <a href="https://united-nations.academia.edu/1" alt="forbes" target="_blank" style={{cursor:'pointer'}}>
                        <img src={un} alt="" />
                        </a>

                      </ul>
                    </div>
                  </div>




                <div>

                  <div className="leadership__item"  >
                    <div className="leadership__item-img">
                      <img src={img2} alt="" />
                    </div>
                    <div className="leadership__item-content">
                      <h3 className="leadership__item-title font-EB">
                        Alex Dutkovsky
                      </h3>
                      <h4 className="leadership__item-undertitle font-EB">
                        Technology
                      </h4>
                      <p className="leadership__item-paragraph font-EB">
                        12 years track record as CTO and lead developer. Executive roles at Silver Spoon, Moon Rabbit and PickAqua.
                        Building on Substrate and Solidity.   </p>
                      <ul className="leadership__item-socials">
                        <a href="https://www.linkedin.com/in/alex-dutkovsky/" target="_blank" style={{cursor:'pointer'}}>
                          <img src={linkedin} alt="" />
                        </a>
                      </ul>
                    </div>
                  </div>

                </div>


                <div>

                  <div className="leadership__item"  >
                    <div className="leadership__item-img">
                      <img src={img3} alt="" />
                    </div>
                    <div className="leadership__item-content">
                      <h3 className="leadership__item-title font-EB">
                        Mateusz Tobola
                      </h3>
                      <h4 className="leadership__item-undertitle font-EB">
                        Product
                      </h4>
                      <p className="leadership__item-paragraph font-EB">
                        15 years track record as visual architect and product designer. Led projects at KrowdFit, Harris Black,
                        OneVim and Moon Rabbit.</p>
                      <ul className="leadership__item-socials">
                        <a href="https://www.linkedin.com/in/mateusz-tobola-91482653/" target="_blank" style={{cursor:'pointer'}}>
                          <img src={linkedin} alt="" />
                        </a>
                      </ul>
                    </div>
                  </div>



                {/*<PageFooter style={{paddingTop:'10vh'}} />*/}


                </div>

                <div
                >
                  <div className="leadership__item"  >
                    <div className="leadership__item-img">
                      <img src={img4} alt="" />
                    </div>
                    <div className="leadership__item-content">
                      <h3 className="leadership__item-title font-EB">
                        Kate Iani
                      </h3>
                      <h4 className="leadership__item-undertitle font-EB">
                        Marketing
                      </h4>
                      <p className="leadership__item-paragraph font-EB">
                        10 years track record as marketing executive: Dogecoin, Moon Rabbit, Ambrosus, Syscoin, Meshine.
                        3 years as investment lawyer.</p>
                      <ul className="leadership__item-socials">
                        <a href="https://www.linkedin.com/in/kate-iani-ab685b36/" target="_blank" style={{cursor:'pointer'}}>
                          <img src={linkedin} alt="" />
                        </a>
                      </ul>
                    </div>
                  </div>
                {/*<LeaderItem key={"4"}*/}
                {/*            img={img4}*/}
                {/*            leaderName={"Kate Iani"}*/}
                {/*            leaderCareer={"Technology"}*/}
                {/*            leaderDiscr={"10 years track record as marketing executive: Dogecoin, Moon Rabbit, Ambrosus, Syscoin, Meshine. 3 years as investment lawyer."}*/}
                {/*    // socials={key.socials}*/}
                {/*    // show={key.showNaming}*/}
                {/*/>*/}
                </div>

                {/*<PageFooter />*/}



                  {/*{leaderCollection}*/}

              </div>

            {/*<PageFooter />*/}

          </div>

            {/*<div data-scroll*/}
            {/*     data-scroll-direction="horizontal"*/}
            {/*     data-scroll-speed="-7"*/}
            {/*>*/}
        {/*<PageFooter />*/}

        {/*</div>*/}

        <div className="landing__bottom-content">

          <div className="landing__bottom-wrap"
               data-scroll
               data-scroll-direction="vertical"
               data-scroll-speed="7"

          >
            <div
                // style={{marginLeft:'90vw', marginTop: '100vh'}}
                data-scroll
                 data-scroll-direction="horizontal"
                 data-scroll-speed="-5"
                // className="landing__bottom-left-module"
                className="menu-full"
            >

             

              <div className="landing__bottom-content__networks">


                <a rel="noreferrer" target="_blank" className="menu-item" href="https://twitter.com/wsb_sh?s=21">
                  <div>
                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                    <span style={{"--index":1, fontSize: '16px'}}>W</span>
                    <span style={{"--index":2, fontSize: '16px'}}>I</span>
                    <span style={{"--index":3, fontSize: '16px'}}>T</span>
                    <span style={{"--index":4, fontSize: '16px'}}>T</span>
                    <span style={{"--index":5, fontSize: '16px'}}>E</span>
                    <span style={{"--index":6, fontSize: '16px'}}>R</span>
                  </div>
                  <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                    <span style={{"--index":1, fontSize: '16px'}}>W</span>
                    <span style={{"--index":2, fontSize: '16px'}}>I</span>
                    <span style={{"--index":3, fontSize: '16px'}}>T</span>
                    <span style={{"--index":4, fontSize: '16px'}}>T</span>
                    <span style={{"--index":5, fontSize: '16px'}}>E</span>
                    <span style={{"--index":6, fontSize: '16px'}}>R</span>
                  </div>
                </a>


                {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TWITTER</a></span>*/}
              </div>

              <div className="landing__bottom-content__networks">

                <a rel="noreferrer" target="_blank" className="menu-item" href="https://t.me/wsbsh">
                  <div>
                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                    <span style={{"--index":2, fontSize: '16px'}}>L</span>
                    <span style={{"--index":3, fontSize: '16px'}}>E</span>
                    <span style={{"--index":4, fontSize: '16px'}}>G</span>
                    <span style={{"--index":5, fontSize: '16px'}}>R</span>
                    <span style={{"--index":6, fontSize: '16px'}}>A</span>
                    <span style={{"--index":7, fontSize: '16px'}}>M</span>

                  </div>
                  <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                    <span style={{"--index":0, fontSize: '16px'}}>T</span>
                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                    <span style={{"--index":2, fontSize: '16px'}}>L</span>
                    <span style={{"--index":3, fontSize: '16px'}}>E</span>
                    <span style={{"--index":4, fontSize: '16px'}}>G</span>
                    <span style={{"--index":5, fontSize: '16px'}}>R</span>
                    <span style={{"--index":6, fontSize: '16px'}}>A</span>
                    <span style={{"--index":7, fontSize: '16px'}}>M</span>
                  </div>
                </a>


                {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
              </div>
              <div className="landing__bottom__separator-line"></div>
              <div className="landing__bottom-content__networks">

                <a rel="noreferrer" target="_blank" className="menu-item" href="/terms">
                  <div>
                    <span style={{"--index":0, fontSize: '16px'}}>L</span>
                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                    <span style={{"--index":2, fontSize: '16px'}}>G</span>
                    <span style={{"--index":3, fontSize: '16px'}}>A</span>
                    <span style={{"--index":4, fontSize: '16px'}}>L</span>
                  </div>
                  <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                    <span style={{"--index":0, fontSize: '16px'}}>L</span>
                    <span style={{"--index":1, fontSize: '16px'}}>E</span>
                    <span style={{"--index":2, fontSize: '16px'}}>G</span>
                    <span style={{"--index":3, fontSize: '16px'}}>A</span>
                    <span style={{"--index":4, fontSize: '16px'}}>L</span>
                  </div>
                </a>

                {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
              </div>
              <div className="landing__bottom__separator-line"></div>
                        <div className="landing__bottom-content__networks">

                          <a rel="noreferrer" target="_blank" className="menu-item" href="https://www.coingecko.com/en/coins/wsb-sh">
                              <div>
                                  <span style={{"--index":0, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":1, fontSize: '16px'}}>O</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>N</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>E</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>K</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>O</span>
                              </div>
                              <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '16px'}}>
                                  <span style={{"--index":0, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":1, fontSize: '16px'}}>O</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>N</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>E</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>K</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>O</span>
                              </div>
                          </a>

                        {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                        </div>

             
            </div>
          </div>
        </div>


      </div>

  </div>
  );
}
